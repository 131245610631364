import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useWindowSize from '../hooks/useGatsbyWindowSize'

export default function PostPreview() {
    const { height, width } = useWindowSize()

    const data = useStaticQuery(graphql`
    {
      allDatoCmsReview(limit: 4, sort: {fields: meta___createdAt, order: DESC}) {
        edges {
          node {
            jobtype
            shortreview
            posttitle
            reviewpicture {
              alt
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    `)

    let layout = []

    // fix
    // loops through all postings in desc order(newest at top) then formats them whatever way the sceensize dictates. when
    //the breakpoint is small it pushes a one column into the array if it is bigger than sm it pushes a two column layout

    for (let i = 0; i < data.allDatoCmsReview.edges.length; i++) {
        let routeString = data.allDatoCmsReview.edges[i].node.posttitle.toLowerCase();
        let route = routeString.replace(/ ( +(&| +) +)| +/g, "-");
        let finalRoute = route.replace(/'/g, "");

        // coplicated regex makes sure url conforms to teplated url. Takes spaces, &, +  and replaces with - 
        let linkAddress = `${finalRoute}/`
        console.log(linkAddress)


        if (width > 640) {
            // to give split column feel i have to inverse the layout based on index
            if (i % 2 == 0) {
                layout.push(
                    <Link to={linkAddress} className='no-underline'>
                        < div className='flex flex-wrap text-white' >
                            <span className='w-full sm:w-1/2'>
                                <GatsbyImage className='min-w-sm max-w-full ' image={getImage(data.allDatoCmsReview.edges[i].node.reviewpicture.gatsbyImageData)} />
                            </span>
                            <span className='flex  w-full sm:w-1/2'>
                                <span className='inline my-auto text-start px-8'>
        
                                    <h2 style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>{data.allDatoCmsReview.edges[i].node.posttitle}</h2>
                                    <p style={{fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>{data.allDatoCmsReview.edges[i].node.shortreview}</p>
                                    {/* <p className='inline text-xs px-2 py-1 rounded-full shadow hover:shadow-md outline-none btnColor'>{data.allDatoCmsReview.edges[i].node.jobtype}</p> */}
                                </span>
                            </span>
                        </div>
                    </Link>
                );
            }
            else {
                layout.push(
                    <Link to={linkAddress} className='no-underline'>
                        < div className='flex flex-wrap text-white' >
                            <span className='flex w-full sm:w-1/2'>
                                <span className='inline my-auto px-8 text-end'>
                                    <h2 style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>{data.allDatoCmsReview.edges[i].node.posttitle}</h2>
                                    <p style={{fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>{data.allDatoCmsReview.edges[i].node.shortreview}</p>
                                    {/* <p className='inline text-xs px-2 py-1 rounded-full shadow hover:shadow-md outline-none btnColor'>{data.allDatoCmsReview.edges[i].node.jobtype}</p> */}
                                </span>
                            </span>
                            <span className='w-full sm:w-1/2'>
                                <GatsbyImage className='min-w-sm max-w-full w-100 h-100' image={getImage(data.allDatoCmsReview.edges[i].node.reviewpicture.gatsbyImageData)} />
                            </span>
                        </div>
                    </Link>
                );
            }
        }
            
        else {
        if (i % 2 == 0) {
            layout.push(
                <Link to={linkAddress} className='no-underline'>
                    < div className='flex flex-wrap text-white' >
                        <span className='w-full  order-2 md:w-1/2'>
                            <GatsbyImage className='min-w-sm max-w-full ' image={getImage(data.allDatoCmsReview.edges[i].node.reviewpicture.gatsbyImageData)} />
                            {console.log(data.allDatoCmsReview.edges[i].node.reviewpicture.gatsbyImageData)}
                        </span>
                        <span className='flex w-full md:w-1/2 my-8'>
                            <span className='inline my-auto text-start px-1 my-8'>
                                <h2 style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>{data.allDatoCmsReview.edges[i].node.posttitle}</h2>
                                <p style={{fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>{data.allDatoCmsReview.edges[i].node.shortreview}</p>
                                {/* <p className='inline text-xs px-2 py-1 rounded-full shadow hover:shadow-md outline-none btnColor '>{data.allDatoCmsReview.edges[i].node.jobtype}</p> */}
                            </span>
                        </span>
                    </div>
                </Link>
            );
        }
        else {
            layout.push(
                <Link to={linkAddress} className='no-underline'>
                    < div className='flex flex-wrap text-white' >
                        <span className='flex w-full md:w-1/2 my-8'>
                            <span className='inline my-auto px-1 text-end'>
                                <h2 style={{fontFamily: "linotype-didot, serif", fontSize: "30px"}}>{data.allDatoCmsReview.edges[i].node.posttitle}</h2>
                                <p style={{fontFamily: "Josefin sans, sans-serif", fontSize: "17px"}}>{data.allDatoCmsReview.edges[i].node.shortreview}</p>
                                {/* <p className='inline text-xs px-2 py-1 rounded-full shadow hover:shadow-md outline-none btnColor'>{data.allDatoCmsReview.edges[i].node.jobtype}</p> */}
                            </span>
                        </span>
                        <span className='w-full md:w-1/2'>
                            <GatsbyImage className='min-w-sm max-w-full w-100 h-100' image={getImage(data.allDatoCmsReview.edges[i].node.reviewpicture.gatsbyImageData)} />
                        </span>
                    </div>
                </Link>
            );
        }
    }
}


    return (
        <section className='flex flex-col justify-center mx-auto'>
            {layout}  
        </section>
    )
    }

