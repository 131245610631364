import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'


// layout component raps all pages. 

export default function Layout({children}) {
    return (
        <div className=' flex flex-col h-screen backgroundColor'>
            <Navbar />
            <div className='flex-1 overflow-y-auto backgroundColor text-white'>
                {children}
            <Footer className='border border-red-500 p-5 m-5' />
            </div>
        </div>
    )
}
