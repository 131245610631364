import { Link } from 'gatsby'
import React from 'react'

export default function Footer() {
    return (
        <footer className="flex flex-col darkAccent text-center sm:flex-row sm:text-left sm:justify-between py-3 px-6 sm:items-baseline w-full text-white">
            <script
  dangerouslySetInnerHTML={{ __html: `
  _linkedin_partner_id = "3902785"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); </script><script type="text/javascript"> (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
  `}}
/>
<noscript><img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=3902785&fmt=gif" /> </noscript>
            <div>
                <Link className='sm:ml-24' style={{display:"inline-block"}} to='/'><img src="https://www.datocms-assets.com/56195/1633640923-nom-logo-header.svg"></img></Link>
                <p className='sm:ml-24 sm:mx-auto' style={{fontFamily: "Josefin sans, sans-serif", textTransform: "uppercase", fontSize: "12px", paddingLeft: "40px"}}>WORK HARD, PLAY HARD, REST HARD</p>
            </div>
        <div>
           <p style={{fontFamily: "Josefin sans, sans-serif"}} className='text-lg sm:p-0 no-underline sm:ml-2 text-white font-thin text-xs text-opacity-50 sm:mr-24'>&copy; {new Date().getFullYear()} Noble Ocean Media</p>
        </div>
  </footer>
    )
}
