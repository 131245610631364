
import React from 'react'
import Layout from '../../components/Layout'
import PostPreview from '../../components/PostPreview'
import SEO from '../../components/SEO'

export default function Portfolio() {
    return (
        <Layout>
             <SEO title='Portfolio Page' description='Check out some of our latest work'/>
            <div className='px-8 py-6 md:mx-16  md:py-12'>
            <PostPreview/>
            </div>
        </Layout>
    )
}